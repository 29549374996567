var render = function () {
  var _vm$package, _vm$package2, _vm$package3, _vm$package4, _vm$package5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-checkout"
  }, [_c('div', {
    staticClass: "left-side pt-5"
  }, [_vm.regulation ? _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.regulation)
    }
  }) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100"
  }, [_c('b-spinner', {
    staticStyle: {
      "width": "5rem",
      "height": "5rem",
      "margin-top": "10rem"
    }
  })], 1)]), _c('div', {
    staticClass: "right-side"
  }, [_c('div', {
    staticClass: "right-side__card"
  }, [_vm.package ? [_c('div', {
    staticClass: "information"
  }, [_c('h2', {
    staticClass: "information__headline"
  }, [_vm._v(_vm._s(_vm.package.name))]), _c('div', {
    staticClass: "information__description"
  }, [_vm._v(_vm._s(_vm.package.description))]), _vm.package.price.value ? _c('div', {
    staticClass: "information__row"
  }, [_c('p', {
    staticClass: "information__label"
  }, [_vm._v("PRICING")]), _c('p', {
    staticClass: "information__price"
  }, [_vm._v(" + " + _vm._s(_vm._f("thousandSeparator")(_vm.package.price.value)) + " " + _vm._s(_vm.package.price.currency)), ((_vm$package = _vm.package) === null || _vm$package === void 0 ? void 0 : _vm$package.plan) !== 'One-off' ? _c('span', [_vm._v(" / " + _vm._s(_vm.paymentPlan))]) : _vm._e()])]) : _vm._e()]), !_vm.package.active ? _c('sygni-checkbox', {
    staticClass: "secondary normalize",
    model: {
      value: _vm.areRegulationsAccepted,
      callback: function callback($$v) {
        _vm.areRegulationsAccepted = $$v;
      },
      expression: "areRegulationsAccepted"
    }
  }, [_vm._v(" I confirm that I have read the contract and accept its terms and conditions and that I have the capacity to represent the legal entity or have been authorised by the management to enter into a legally binding contract. ")]) : _vm._e(), _c('sygni-rounded-button', {
    class: [_vm.package.active ? 'custom active' : 'filled gn-primary', _vm.packageId ? 'mb-4' : 'mb-5'],
    attrs: {
      "disabled": !_vm.areRegulationsAccepted
    },
    on: {
      "click": function click($event) {
        return _vm.accept();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.package.active ? 'Active' : 'Accept') + " ")]), (_vm$package2 = _vm.package) !== null && _vm$package2 !== void 0 && _vm$package2.active && ((_vm$package3 = _vm.package) === null || _vm$package3 === void 0 ? void 0 : _vm$package3.name) === 'Wezwanie KNF ws. ESG w ASI' ? _c('sygni-rounded-button', {
    staticClass: "filled gn-primary mb-4",
    on: {
      "click": _vm.goToInsight
    }
  }, [_vm._v("Go to product")]) : _vm._e(), ((_vm$package4 = _vm.package) === null || _vm$package4 === void 0 ? void 0 : _vm$package4.name) === 'Regulatory filing (KNF Reporting)' ? _c('sygni-rounded-button', {
    staticClass: "filled gn-primary mb-4",
    attrs: {
      "disabled": !((_vm$package5 = _vm.package) !== null && _vm$package5 !== void 0 && _vm$package5.active)
    },
    on: {
      "click": _vm.goToRegReporting
    }
  }, [_vm._v("Go to Reg Reporting")]) : _vm._e(), _vm.packageId ? _c('sygni-rounded-button', {
    staticClass: "outline gn-secondary mb-5",
    on: {
      "click": _vm.generatePdf
    }
  }, [_vm._v("Download PDF")]) : _vm._e()] : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }